import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalStyles } from "./globalStyles";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home/Home";
import Carrefour from "./pages/Carrefour/Carrefour";
import Expositions from "./pages/Expositions/Expositions";
// import EventsAndWorkshops from './pages/EventsAndWorkshops/EventsAndWorkshops'
import Cabane from "./pages/Cabane/Cabane";
import Research from "./pages/Research/Research";
import Brusonette from "./pages/Brusonette/Brusonette";
import Residences from "./pages/Residences/Residences";
import Projects from "./pages/Projects/Projects";
import About from "./pages/About/About";
import Partners from "./pages/Partners/Partners";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Market from "./pages/Market/Market";
import CheesesMarket from "./pages/CheesesMarket/CheesesMarket";

const App = () => {
  return (
    <Router>
      <Header />
      <GlobalStyles />
      <ScrollToTop />
      <Routes>
        <Route path="/partenaires" element={<Partners />} />
        <Route path="/qui-sommes-nous" element={<About />} />
        <Route path="/projets" element={<Projects />} />
        <Route path="/recherche" element={<Research />} />
        <Route path="/residences" element={<Residences />} />
        <Route path="/epicerie-la-brusonette" element={<Brusonette />} />
        <Route path="/cabane-a-bd" element={<Cabane />} />
        {/* <Route path='/evenements-et-ateliers' element={<EventsAndWorkshops />} /> */}
        <Route path="/expositions" element={<Expositions />} />
        <Route path="/grand-marche-des-terroirs-alpins" element={<Market />} />
        <Route path="/carrefour-du-village" element={<Carrefour />} />
        <Route path="/marche-des-fromages" element={<CheesesMarket />} />
        <Route path="/" exact element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
