import { useEffect, useRef } from "react";
import { closeMenu, openMenu, staggerReveal } from "../../animations/menu";
import { StyledMenu, Background, Wrapper, List } from "./Menu.styled";
import { Container } from "../../globalStyles";
import categories from "../../data/categories";
import { Link } from "react-router-dom";

const Menu = ({ state, toggle }) => {
  let menu = useRef(null);
  let revealMenu = useRef(null);
  let revealMenuBackground = useRef(null);

  useEffect(() => {
    if (state === false) {
      closeMenu(revealMenu, revealMenuBackground, menu);
    } else {
      openMenu(menu, revealMenuBackground, revealMenu);
      staggerReveal(revealMenuBackground, revealMenu);
    }
  }, [state]);

  return (
    <StyledMenu ref={(el) => (menu = el)}>
      <Background ref={(el) => (revealMenuBackground = el)}></Background>
      <Wrapper ref={(el) => (revealMenu = el)}>
        <Container>
          <List>
            <ul>
              {categories.map((category, index) => (
                <li key={index} onClick={toggle}>
                  <Link to={`${category.url}`} target={category.target}>
                    <span>{category.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </List>
        </Container>
      </Wrapper>
    </StyledMenu>
  );
};

export default Menu;
