import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
  List,
} from "../../globalStyles";
import { Application } from "./Residences.styled";
import band from "../../assets/images/residences/band.jpg";
import pianist from "../../assets/images/residences/pianist.jpg";
import { Link } from "../../globalStyles";
import form from "../../assets/images/residences/formulaire-sejour-residence.docx";

const Residences = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Résidences</span>
            </h2>
            <h4>Accueil d'artistes et de chercheurs</h4>
            <Paragraph>
              <p>
                Lieux de vie et véritables espaces de rencontres, les résidences
                proposées par le PALP s’établissent dans le village de Bruson.
                Elles ont pour but d’offrir des conditions propices au
                développement de projets culturels, de favoriser la création
                artistique, de penser et réaliser des œuvres participatives et
                d’offrir des pistes de recherche autour du patrimoine alpin.
              </p>
              <p>
                Le fruit de ces séjours en résidence contribue au développement
                du Palp Village et sont à découvrir au travers du Festival et
                des projets du Village.
              </p>
              <p>
                La résidence est à disposition d’artiste pour des collaborations
                avec le PALP, tout autant que pour des projets artistiques
                personnels. Elle est également à louer sur AirBnB durant les
                périodes de vacances scolaires. N’hésitez pas à nous écrire!
              </p>
            </Paragraph>
            <Paragraph style={{ marginBottom: "3.5rem" }}>
              <h4>Ils étaient là</h4>
              <List>
                <li>Them Fleurs: concert aux Carnotzet, création PALP</li>
                <li>Ko Shin Moon: concert au Bal Masqué, création PALP</li>
                <li>
                  Mia Oud et Jackstaff and the two old boys: résidence sur deux
                  week-ends, concerts au Carrefour et à Spaziergäng
                </li>
                <li>Hélène Becquelin: Les Chroniques Palpiennes (BD)</li>
                <li>
                  Emilie Suchet: création de A à Z d’une pièce de l’exposition
                  «PASSAGE»
                </li>
                <li>
                  Louis Levesque: création dans le cadre de l’exposition 2024 du
                  Musée de Bagnes
                </li>
              </List>
            </Paragraph>
            <Application>
              <h4 style={{ fontSize: "2.25rem", lineHeight: "1.3" }}>
                Appel à candidatures
              </h4>
              <h4>Résidence de création ou de recherche</h4>
              <Paragraph>
                <p>
                  Passez quelques jours à Bruson, dans ce petit village de
                  montagne idyllique, pour consacrer du temps à vos projets et
                  leur donner vie.
                </p>
                <p>
                  Au sein du village de Bruson, au-dessus du restaurant le
                  Carrefour du Village, la résidence du PALP attend vos projets
                  ! Nous mettons à disposition trois chambres – 7 lits – une
                  cuisine, une salle de bain, ainsi qu’une pièce spacieuse pour
                  travailler et développer vos idées. Tout le confort d’un
                  chalet dans ce cadre bucolique alpin.
                </p>
              </Paragraph>
              <h4>Deux modèles de séjours en résidence sont possibles</h4>
              <Paragraph>
                <List>
                  <li style={{ marginBottom: "8px" }}>
                    Vous souhaitez proposer une création ou une recherche en
                    lien avec les activités du PALP et dans le cadre de l’un de
                    ses évènements. Il peut s’agir de l’écriture d’un album ou
                    la création d’un live qui seront joués durant la saison du
                    festival, ou encore d’une recherche scientifique ou
                    artistique en lien avec un projet du PALP Village. Dans ce
                    cas-là, les frais de résidence sont offerts en échange d’une
                    représentation, performance, animation, étude. Selon
                    l’ampleur du projet, nous pouvons également envisager et
                    discuter d’un budget spécifique pour sa réalisation.
                  </li>
                  <li style={{ marginBottom: "8px" }}>
                    Vous souhaitez avoir accès au lieu pour travailler sur un
                    projet privé ou distinct du PALP Festival et Village. Dans
                    ce cas-là, nous vous proposons un prix attractif.
                  </li>
                </List>
              </Paragraph>
              <h4>Durée des séjours en résidence</h4>
              <Paragraph>
                <p>
                  La durée de votre séjour en résidence varie en fonction de vos
                  besoins.
                </p>
              </Paragraph>
              <h4>Périodes des séjours en résidence</h4>
              <Paragraph>
                <p>
                  Pour vos séjours en résidence, nous vous offrons deux périodes
                  par année.
                </p>
                <List>
                  <li style={{ marginBottom: "8px" }}>Avril à Juillet</li>
                  <li style={{ marginBottom: "8px" }}>Septembre à Novembre</li>
                </List>
              </Paragraph>
              <h4>Procédure</h4>
              <Paragraph>
                <p>
                  Nous recevons très volontiers votre dossier à l’adresse
                  suivante:{" "}
                  <Link href="mailto:residence@palpfestival.ch">
                    residence@palpfestival.ch
                  </Link>
                  . Deux délais sont fixés en fonction de la période de
                  résidence souhaitée, à savoir.
                </p>
                <List>
                  <li>
                    Pour une résidence entre avril et juillet: le délai pour
                    l’envoi de votre dossier est fixé au 30 décembre
                  </li>
                  <li>
                    Pour une résidence entre septembre et novembre: le délai
                    pour l’envoi de votre dossier est fixé au 30 mai
                  </li>
                </List>
              </Paragraph>
              <h4>Votre dossier doit contenir</h4>
              <Paragraph>
                <List>
                  <li>Le formulaire complété</li>
                  <li>
                    Le nom de votre association / organisation / personne avec
                    un bref historique de vos projets, activités, etc.
                  </li>
                  <li>
                    Un descriptif du projet développé en relation avec le PALP
                    lors de la résidence (si vous souhaitez séjourner à Bruson
                    pour un projet privé et/ou distinct, une brève description
                    du projet suffit)
                  </li>
                </List>
              </Paragraph>
              <Paragraph>
                <p>Nous nous réjouissons de découvrir vos projets!</p>
              </Paragraph>
              <Paragraph>
                <p>Cordialement,</p>
              </Paragraph>
              <Paragraph>
                <p>L’équipe du PALP</p>
              </Paragraph>
              <Paragraph style={{ marginBottom: "0rem" }}>
                <p>
                  <Link href={form} target="_blank" rel="noreferrer">
                    Formulaire
                  </Link>
                </p>
              </Paragraph>
            </Application>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={band} alt="Un groupe de musique en résidence" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={pianist} alt="Un pianiste en résidence" />
              <figcaption>© PALP Village</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default Residences;
