import { Main, Container, Wrapper, LeftColumn, Paragraph, RightColumn, Image } from '../../globalStyles'
import recherchePeople from '../../assets/images/research/people.jpg'
import rechercheWorkshop from '../../assets/images/research/workshop.jpg'

const Research = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Recherche</span>
            </h2>
            <h4>Inviter la réflexion pour le développement des communes de montagne</h4>
            <Paragraph>
              <p>Des recherches en sciences humaines, des partenariats avec des institutions culturelles régionales et des universités permettent de produire des données relatives au territoire du projet «PALP Village». L’analyse d’entretiens individuels et d’études bibliographiques permettent d’établir un canevas thématique d’une multitude de sujets à explorer.</p>
              <p>Tables rondes, colloques, brainstorming sont organisés avec des invités actifs dans différents domaines de la société, conviés à partager leur regard sur l’avenir des villages de montagne et sur l’impact de projets culturels dans la réactivation d’un nouveau dynamisme.</p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={recherchePeople} alt='Un groupe de personnes' />
              <figcaption>© Nicolas Sedlatchek</figcaption>
            </Image>
            <Image>
              <img src={rechercheWorkshop} alt='Un workshop' />
              <figcaption>© Nicolas Sedlatchek</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  )
}

export default Research
