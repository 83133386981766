import { useState } from "react"
import { Main, Container, Wrapper, LeftColumn, Paragraph, List, RightColumn, Image } from '../../globalStyles'
import roulibouli from '../../assets/images/expositions/roulibouli.jpg'
import secretsInterieur from '../../assets/images/expositions/secrets-interieur.jpg'
import secretsExterieur from '../../assets/images/expositions/secrets-exterieur.jpg'

const Expositions = () => {
  const [displayRouliBouli, setDisplayRouliBouli] = useState('roulibouli')

  const clickRouliBouli = () => {
    setDisplayRouliBouli('roulibouli')
  }
  
  const clickSecrets = () => {
    setDisplayRouliBouli('secrets')
  }

  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Expositions</span>
            </h2>
            <div style={{ marginBottom: '2rem', display: 'flex', gap: '1rem' }}>
              <button onClick={() => clickRouliBouli()} style={displayRouliBouli === 'roulibouli' ? {color: '#ff4d6d', textDecoration: 'underline' } : null}>
                Rouli Bouli
              </button>
              <button onClick={() => clickSecrets()} style={displayRouliBouli === 'secrets' ? {color: '#ff4d6d', textDecoration: 'underline' } : null}>
                Les Secrets
              </button>
            </div>
            {displayRouliBouli === 'roulibouli' ? (
              <>
                <h4 style={{ fontSize: '3rem' }}>Rouli Bouli</h4>
                <h4>Le parcours de balle</h4>
                <Paragraph>
                  <p>En 2024 le PALP Village investit le chemin pédestre reliant Bruson au Châble.</p>
                  <p>Les promeneurs et visiteurs se procurent une balle en bois avant de suivre un itinéraire ponctué d’oeuvres et d’animations didactiques principalement fabriquées en bois ou en matériaux de récupération. Les oeuvres sont faites pour accueillir la course d’une ou plusieurs balles en bois de 6 cm de diamètre.</p>
                  <p>Les balles en bois se balades à travers des structures qui les guident. Mécaniques ingénieuses, balade poétique, circuit initiatique (Les bois de nos forêts), péripéties et courses folles…</p>
                </Paragraph>
                <Paragraph>
                  <h4>Les installations</h4>
                  <p>Une dizaine d’installations seront créées par différents intervenants, artisans locaux, artistes régionaux...</p>
                  <p>Un demi-kilomètre de chéneaux, longe le chemin et nous conduit à chaque oeuvre.</p>
                  <p>Les chéneaux sont fabriqués par le triage forestier Combins-Catogne. Leurs longueurs et formes, leurs essences ainsi que leurs types de fabrication sont diverses et variées.</p>
                </Paragraph>
                <Paragraph>
                  <h4>Le chemin pédestre</h4>
                  <List>
                    <li>2,4 km / 42 minutes à pied</li>
                    <li>181 m &darr; / 0 m &uarr;</li>
                    <li>Depuis le restaurant Le Carrefour du Village > Chemin du Seudzay > Chemin des Bamettes > Route de Clouchèvre > pour finir au pont du Torrent de Bruson</li>
                  </List>
                </Paragraph>
                <Paragraph>
                  <h4>Date</h4>
                  <List>
                    <li>Ouverture et vernissage : Samedi 1 juin 2024</li>
                    <li>Durée: 3 mois</li>
                    <li>Fin: Dimanche 1er septembre 2024</li>
                    <li>Création et construction: 2023, 2024</li>
                    <li>Montage: du 15 au 24 mai 2024</li>
                  </List>
                </Paragraph>
              </>
            ) : (
              <>
                <h4 style={{ fontSize: '3rem' }}>Les Secrets</h4>
                <h4>Les Secrets du val de Bagnes, une invitation à voir l'invisible d'un paysage traversé</h4>
                <Paragraph>
                  <p>Un itinéraire en boucle. Vingt-cing kilomètres à parcourir à pied ou à vélo, où les hameaux et les villages s'acoquinent avec les zones de prairies et de forêts. Le territoire se voit, se sent, mais laisse hors de portée du regard la couleur des lieux habités et vécus par ses habitants.</p>
                  <p>Entre juillet 2021 et février 2022, onze photographes ont été invités à rencontrer quelques habitantes et habitants. Fruits de leurs échanges, les images livrées nous racontent des expériences, des relations aux vivants et aux éléments.</p>
                  <p>Du langage des arbres aux vertus des plantes médicinales, en passant par les mvstères des pierres à cupules ou la magie de l'alambic, les photographes dévoilent des mondes réservés à ceux qui savent, qui pratiquent. Jouant sur la perception d'ambiance, les images offrent un aperçu sur des préoccupations, des connaissances et des savoir-faire singuliers. Représentation de secrets pour les uns ou d'évidence pour les autres!</p>
                  <p>Les Secrets du val de Bagnes est un projet du PALP Village, réalisé en collaboration avec near. association suisse pour la photographie contemporaine, avec le soutien de la commune et de la société de développement du Val de Bagnes.</p>
                  <p>Cette exposition n'aurait pas été possible sans la précieuse aide de Jean-Charles Fellay du CREPA ainsi que celle des habitantes et habitants du val de Bagnes.</p>
                  <p style={{ fontStyle: 'italic' }}>Curation: Julie Dayer et Stéphanie Jacot-Descombes <br /> Production: Sophie Nolan <br /> Graphisme: Blaise Coutaz <br /> Médiation: Mélanie Hugon-Duc, Sophie Nolan et Loise Pignat
                  </p>
                </Paragraph>
                <Paragraph>
                  <h4>Horaires d’ouverture</h4>
                  <p>Horaire libre</p>
                </Paragraph>
                <Paragraph>
                  <h4>Infos pratiques</h4>
                  <List>
                    <li>Exposition en extérieur.</li>
                    <li>Gratuite.</li>
                    <li>Sentier de 25km principalement gravillonné.</li>
                    <li>2h30 à vélo électrique ou 6h00 à pied (sans pause).</li>
                    <li>Accessible aux poussettes.</li>
                    <li>A faire en une ou plusieurs fois.</li>
                    <li>&uarr; 600 m / &darr; 600 m</li>
                  </List>
                </Paragraph>
              </>
            )}
          </LeftColumn>
          <RightColumn>
            {displayRouliBouli === 'roulibouli' ? (
              <>
              <Image>
                <img src={roulibouli} alt="Le parcours de balle" />
                <figcaption>© PALP Village</figcaption>
              </Image>
              </>
            ) : (
              <>
                <Image>
                  <img src={secretsInterieur} alt='Un workshop' />
                  <figcaption>© PALP Village</figcaption>
                </Image>
                <Image>
                  <img src={secretsExterieur} alt='Un workshop' />
                  <figcaption>© PALP Village</figcaption>
                </Image>
              </>
            )}
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  )
}
export default Expositions
