import { Main, Container, Wrapper, LeftColumn, Paragraph, RightColumn, Image } from '../../globalStyles'
import { Articles } from './About.styled'
import articleCabane from '../../assets/images/about/article-cabane.jpg'
import vinyls from '../../assets/images/about/vinyls.jpg'
import concert from '../../assets/images/about/concert.jpg'

const About = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Qui sommes-nous</span>
            </h2>
            <h4>Le PALP c’est qui, c’est quoi, c’est comment?</h4>
            <Paragraph>
              <p>«PALP» ... cela sonne comme un battement du cœur. Un cœur amoureux de la culture, du terroir, de la gastronomie, du patrimoine, des traditions et bien plus encore. De chacune de ses pulsations émane une idée créative et innovante.</p>
              <p>Le PALP, c’est une association qui vise à valoriser la culture et le terroir. C’est un trait d’union entre la plaine et les cimes, le Valais et le monde, l’art et les gens. Il est un cœur qui vibre et qui rallie sur une même fréquence des personnes de tout âge et de tous horizons.</p>
              <p>Dans ce cœur battent deux projets: le PALP Village et le PALP Festival.</p>
            </Paragraph>
            <Paragraph>
              <h4>PALP Village</h4>
              <p>Le PALP Village est un projet pour les communes et régions de montagne lancé à Bruson en 2019 avec l’implantation du bureau de l’équipe afin de tisser des liens étroits avec les habitants du village. Cette place privilégie l’ancrage local et l’économie circulaire et favorise la mise en œuvre de projets participatifs avec des acteurs de la région. L’envie est d’explorer et de développer des projets créatifs, participatifs et communautaires au carrefour entre plusieurs disciplines favorisant le circuit court, le tourisme durable, la réflexion sur les enjeux des régions alpines, la valorisation de l’agriculture de montagne ou encore la promotion des savoir- faire régionaux et des traditions vivantes.
              </p>
              <p>On y trouve Le Carrefour du Village – Bistrot, résidences d’artistes et lieu de création, l’épicerie La Brusonnette, la cabane à BDs créée par les Frères Chapuisat, des résidences d’artistes et de chercheurs, des expositions, des ateliers, des animations, etc. et surtout, des gens qui ont du cœur à l’ouvrage!</p>
            </Paragraph>
            <Paragraph>
              <h4>PALP Festival</h4>
              <p>Le PALP Festival, c’est comme le cœur d’un fruit magique qui se déguste avec gourmandise et qui se partage sans modération aussi bien en plaine qu’en région de montagne.</p>
              <p>Il a été créé en 2010 et débute chaque année au printemps. Il bat son plein en été et se termine en automne. Avec chaque année plus d’une vingtaine d’évènements uniques dans plusieurs communes et régions valaisannes, il métamorphose la musique, les expositions, les saveurs du terroir, les spectacles ou encore les activités littéraires.</p>
            </Paragraph>
            <Paragraph>
              <h4>Articles de presse</h4>
              <Articles>
                <li>
                  <a href={articleCabane} target='_blank' rel='noreferrer'>Cabane à BD</a>
                </li>
              </Articles>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={vinyls} alt='Disques' />
              <figcaption>© Cyril Perregeaux</figcaption>
            </Image>
            <Image>
              <img src={concert} alt='Concert' />
              <figcaption>© Cyril Perregeaux</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  )
}

export default About
