import { Main, Container, Wrapper } from '../../globalStyles'
import { Column, Grid } from './Partners.styled'
import communeBagnes from '../../assets/images/partners/commune-bagnes.png'
import loterieRomande from '../../assets/images/partners/loterie-romande.png'
import vsDepartementEconomie from '../../assets/images/partners/vs-departement-economie.png'
import cultureValais from '../../assets/images/partners/culture-valais.png'
import valBagnes from '../../assets/images/partners/val-bagnes.png'
import nouvellePolitique from '../../assets/images/partners/nouvelle-politique.png'
import leTemps from '../../assets/images/partners/le-temps.png'
import leNouvelliste from '../../assets/images/partners/le-nouvelliste.png'
import rts from '../../assets/images/partners/rts.png'
import raiffeisen from '../../assets/images/partners/raiffeisen.png'
import mobiliere from '../../assets/images/partners/mobiliere.png'
import altis from '../../assets/images/partners/altis.png'
import payot from '../../assets/images/partners/payot.png'

const Partners = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <Column>
            <h2>
              <span>Partenaires</span>
            </h2>
            <h4>Institutionnels</h4>
              <Grid>
                <img src={communeBagnes} alt="Commune de Bagnes logo" />
                <img src={loterieRomande} alt="Loterie romande logo" />
                <img src={vsDepartementEconomie} alt="Valais département économie logo" />
                <img src={cultureValais} alt="Culture Valais logo" />
                <img src={valBagnes} alt="Val de Bagnes logo" />
                <img src={nouvellePolitique} alt="Nouvelle politique régionale logo" />
              </Grid>
            <h4>Médias</h4>
              <Grid>
                <img src={leTemps} alt="Le Temps logo" />
                <img src={leNouvelliste} alt="Le Nouvelliste logo" />
                <img src={rts} alt="RTS logo" />
              </Grid>
            <h4>Principaux</h4>
              <Grid>
                <img src={raiffeisen} alt="Raiffeisen logo" />
                <img src={mobiliere} alt="La Mobilière logo" />
              </Grid>
            <h4>Sponsors</h4>
              <Grid>
                <img src={altis} alt="Altis logo" />
                <img src={payot} alt="Payot logo" />
              </Grid>
          </Column>
        </Wrapper>
      </Container>
    </Main>
  )
}

export default Partners
