import styled from 'styled-components'

export const StyledFooter = styled.footer`
  height: 5rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 8rem;
  }
`

export const FooterContainer = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 1.75rem;
  }

  p {
    font-size: 1rem;
  }
`

export const Icons = styled.ul`
  width: 100px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    width: 75px;
  }
`

export const Icon = styled.li`
  a {
    display: flex;
    color: var(--color-black);
    font-size: 1.5rem;
  }
`
