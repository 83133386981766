import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
  List,
} from "../../globalStyles";
import cheesesMarket from "../../assets/images/cheeses-market/cheeses-market.jpeg";
import expo from "../../assets/images/cheeses-market/expo.jpg";

const CheesesMarket = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Marché des fromages</span>
              <br />
              <span>d'alpage</span>
            </h2>
            <Paragraph>
              <h4>Dégustation et vente directe de fromages</h4>
              <p>
                Dans les vallées de Bagnes, d’Entremont et de Ferret, 9 alpages
                produisent chaque été le fameux Raclette du Valais AOP à partir
                du lait des vaches qui se nourrissent exclusivement des herbages
                fleuris de nos pâturages d’altitude.
              </p>
              <p>
                Profitez de la chance unique de tous les retrouver dans une
                ruelle sympathique du village de Bruson lors de ce marché où
                vous pourrez acquérir des meules ou demi-meules. Partagez un
                verre, une planchette ou une raclette tout en échangeant avec
                ces femmes et ces hommes qui ont passé des semaines en montagne
                pour nous offrir l’or des Alpes, des fromages d’exception.
              </p>
              <p>
                Seront présents dans les ruelles de Bruson neuf alpages de
                Raclette du Valais AOP du Grand Entremont:
              </p>
              <List style={{ marginBottom: "0.5rem" }}>
                <li>Bagnes/La Chaux</li>
                <li>Bagnes/Mille</li>
                <li>Bavon</li>
                <li>Le Coeur</li>
                <li>Le Lein</li>
                <li>Lettaz</li>
                <li>Les Ars</li>
                <li>Peule</li>
                <li>Tronc</li>
              </List>
              <p>
                Nos invités seront les producteurs de fromage du Haut Val de
                Bagnes durement touchés cet été par les intempéries.
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Exposition Limin’alpes</h4>
              <p>
                Limin’alpes propose le regard croisé d’un photographe et d’une
                ethnologue sur ce qui se trame à l’alpage au moment de l’estive.
              </p>
              <p>
                Les images de Pierre Daendliker disent de jour et de nuit
                l’étrangeté d’un monde où cohabitent êtres humains et animaux
                dans un territoire d’altitude. En écho à la série d’images, Léa
                Dorsaz transmet par l’écrit, sous forme de citations, les mots
                que les alpagistes posent sur leur propre expérience.
              </p>
              <p>
                Entre éclairages des perceptions et des pratiques liées à la
                production du raclette et jeux de lumière en image, Limin’alpes
                invite à percevoir autrement la production du raclette à
                l’alpage.
              </p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={cheesesMarket} alt="Marché des fromages d'alpage" />
              <figcaption>© Yves Bochatay</figcaption>
            </Image>
            <Image>
              <img src={expo} alt="Exposition Limin’alpes" />
              <figcaption>© Pierre Daendliker</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default CheesesMarket;
