import styled from 'styled-components'

export const StyledMenu = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 100;
`

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`

export const Wrapper = styled.div`
  background-color: var(--color-white);
  overflow: hidden;
`

export const List = styled.div`
  width: 100%;
  height: calc(100% + 3.5rem);
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    text-align: center;
  }

  li {
    margin: 1.125rem 0;
    transition: all 0.25s ease;

    @media screen and (max-width: 768px) {
      margin: 1rem 0;
    }

    &:hover {
      transform-origin: center;
      transform: rotate(-2deg);
      z-index: 10;

      @media screen and (max-width: 768px) {
        transform-origin: unset;
        transform: none;
      }
    }

    a {
      display: inline-block;
      padding: 0rem 2rem;
      color: var(--color-white);
      font-size: 2rem;

      @media screen and (max-width: 768px) {
        font-size: 1.25rem;
      }

      span {
        line-height: 1.4;
        background-color: var(--color-primary);
        box-shadow: 20px 0 0px 0px var(--color-primary),
          -20px 0 0px 0px var(--color-primary);
      }
    }
  }
`
