import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  /* Variables */
  :root {
    --color-black: #202020;
    --color-white: #f9f9f9;
    --color-primary: #ff4d6d;
    --color-secondary: #ffccd5;
  }

  /* Global */
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    position: relative;
    background-color: var(--color-white);
    font-family: 'adelle-sans', sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.45;
    color: var(--color-black);
  }
  
  h1, h2, h3, h4 {
    font-weight: 600;
    line-height: 1.35;

    @media screen and (max-width: 768px) {
      line-height: 1.45;
    }
  }
  
  h1 {
    font-size: 3.5rem;
    line-height: 1.15;
    
    @media screen and (max-width: 768px) {
      font-size: 2.75rem;
      line-height: 1.2;
    }
  }
  
  h2 {
    font-size: 3rem;
    line-height: 1.2;

    @media screen and (max-width: 768px) {
      font-size: 2.25rem;
    }
  }

  h3 {
    font-size: 2rem;
    line-height: 1.2;
  }

  h4 {
    font-size: 1.5rem;

    @media screen and (max-width: 768px) {
      font-size: 1.375rem;
    }
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  button {
    all: unset;
    cursor: pointer;
    font-size: 1%.5;
  }
`

/* Components */
export const Main = styled.div`
  min-height: calc(100vh - 10rem);
  background-color: var(--color-sky-blue);
`

export const Container = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  height: 100%;
`

export const Wrapper = styled.div`
  padding: 6rem 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    gap: 2.5rem;
    padding: 3rem 0 4rem;
  }
`

export const LeftColumn = styled.div`
  width: 46%;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  h2 {
    display: inline-block;
    margin-bottom: 2rem;
    padding: 0rem 1.375rem;
    color: var(--color-white);
    transition: all 0.25s ease;

    &:hover {
      transform-origin: center;
      transform: rotate(-2deg);
      z-index: 10;

      @media screen and (max-width: 768px) {
        transform-origin: unset;
        transform: none;
      }
    }

    span {
        line-height: 1.4;
        background-color: var(--color-primary);
        box-shadow: 20px 0 0px 0px var(--color-primary),
          -20px 0 0px 0px var(--color-primary);
    }
  }

  h4 {
    margin-bottom: 1.125rem;
  }
`

export const Paragraph = styled.div`
  margin-bottom: 1.5rem;

  h4 {
    margin-bottom: 0.75rem;
  }

  p:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

export const List = styled.ul`
  padding-left: 1.25rem;

  li::before {
    content: "– ";
    display: inline-block;
    width: 1.25rem;
    margin-left: -1.25rem;
  }
`

export const RightColumn = styled.div`
  width: 46%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`

export const Image = styled.div`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  figcaption {
    margin-top: 0.25rem;
    font-size: 1rem;
    font-style: italic;
    text-align: right;

    @media screen and (max-width: 640px) {
      font-size: 0.875rem;
    }
  }
`

export const Link = styled.a`
  color: var(--color-primary);
  text-decoration: underline;
`
