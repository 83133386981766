import styled from 'styled-components'

export const Articles = styled.ul`
  display: flex;
  column-gap: 2rem;

  a {
    color: var(--color-primary);
    text-decoration: underline;
  }
`
