import { StyledFooter, FooterContainer, Icons, Icon } from './Footer.styled'
import { FaFacebookF, FaInstagram } from 'react-icons/fa'

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <p>© 2024, Palp Village, All rights reserved</p>
        <Icons>
          <Icon>
            <a href='https://www.facebook.com/palpfestival' target='_blank' rel='noreferrer'><FaFacebookF /></a>
          </Icon>
          <Icon>
            <a href='https://www.instagram.com/palp_festival/' target='_blank' rel='noreferrer'><FaInstagram /></a>
          </Icon>
        </Icons>
      </FooterContainer>
    </StyledFooter>
  )
}

export default Footer
