import { StyledHero, Wrapper, LeftColumn, RightColumn, Arrow, ArrowImage } from './Hero.styled'
import { Container } from '../../globalStyles'
import villageBruson from '../../assets/images/home/hero.jpg'
import arrow from '../../assets/images/arrow-down.svg'

const Hero = () => {
  return (
    <StyledHero>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h1>
              <span>PALP VILLAGE</span>
            </h1>
            <h2>Lieu culturel et bucolique où il fait bon vivre, rencontrer et partager</h2>
            <h4>Au cœur du val de Bagnes, le PALP Village est un laboratoire de culture et de tourisme, un pôle de recherche et de création en lien étroit avec le développement d’une économie locale et participative en région de montagne. Les bureaux du PALP sont implantés à Bruson pour tisser des liens avec ses habitants et les acteurs de la région.</h4>
          </LeftColumn>
          <RightColumn>
            <img src={villageBruson} alt='Le village de Bruson' />
            <figcaption>© Emilien Itim</figcaption>
          </RightColumn>
        </Wrapper>
        <Arrow>
          <ArrowImage src={arrow} alt='Flèche' />
        </Arrow>
      </Container>
    </StyledHero>
  )
}

export default Hero
