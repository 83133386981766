import {
  StyledCategories,
  CardContainer,
  Card,
  Image,
} from "./Categories.styled";
import { Container } from "../../globalStyles";
import categories from "../../data/categories";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <StyledCategories>
      <Container>
        <CardContainer>
          {categories.map((category, index) => (
            <Link to={category.url} key={index} target={category.target}>
              <Card>
                <Image>
                  <div />
                  <img src={category.image} alt={category.title} />
                </Image>
                <h4>{category.title}</h4>
              </Card>
            </Link>
          ))}
        </CardContainer>
      </Container>
    </StyledCategories>
  );
};

export default Categories;
