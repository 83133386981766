import { Main } from '../../globalStyles'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero/Hero'
import Categories from '../../components/Categories/Categories'

const Home = () => {
  return (
    <Main>
      <Helmet>
        <title>Palp Village</title>
        <meta name='description' content='Au cœur du val de Bagnes, le PALP Village est un laboratoire de culture et de tourisme, un pôle de recherche et de création en lien étroit avec le développement d’une économie locale et participative en région de montagne. Les bureaux du PALP sont implantés à Bruson pour tisser des liens avec ses habitants et les acteurs de la région.'/>
        {/* Facebook META */}
        <meta property='og:title' content='Palp Village' />
        <meta property='og:description' content='Au cœur du val de Bagnes, le PALP Village est un laboratoire de culture et de tourisme, un pôle de recherche et de création en lien étroit avec le développement d’une économie locale et participative en région de montagne. Les bureaux du PALP sont implantés à Bruson pour tisser des liens avec ses habitants et les acteurs de la région.'/>
        <meta property='og:url' content='https://www.palpvillage.ch' />
        <meta property='og:image' content='https://www.palpvillage.ch/og/village-bruson.jpg'/>
        <meta property='og:image:type' content='image/jpg' />
        <meta property='og:type' content='website' />
        {/* Twitter META */}
        <meta name='twitter:title' content='Palp Village' />
        <meta name='twitter:description' content='Au cœur du val de Bagnes, le PALP Village est un laboratoire de culture et de tourisme, un pôle de recherche et de création en lien étroit avec le développement d’une économie locale et participative en région de montagne. Les bureaux du PALP sont implantés à Bruson pour tisser des liens avec ses habitants et les acteurs de la région.'/>
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:creator' content='@palpvillage' />
        <meta name='twitter:site' content='https://www.palpvillage.ch' />
        <meta name='twitter:image' content='https://www.palpvillage.ch/og/village-bruson.jpg'/>
      </Helmet>
      <Hero />
      <Categories />
    </Main>
  )
}

export default Home
