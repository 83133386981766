import styled from 'styled-components'

export const StyledHeader = styled.header`
  height: 5rem;
  position: sticky;
  z-index: 200;
`

export const Nav = styled.nav`
  width: 90%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.img`
  width: 200px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 175px;
  }
`
