import gsap from 'gsap'

const closeMenu = (revealMenu, revealMenuBackground, menu) => {
  gsap.to([revealMenu, revealMenuBackground], {
    duration: 0.8,
    height: 0,
    ease: 'power3.inOut',
    stagger: 0.07,
  })
  gsap.to(menu, {
    duration: 1,
    display: 'none',
  })
}

const openMenu = (menu, revealMenuBackground, revealMenu) => {
  gsap.to(menu, {
    duration: 0,
    display: 'block',
  })
  gsap.to([revealMenuBackground, revealMenu], {
    duration: 0,
    opacity: 1,
    height: '100%',
  })
}

const staggerReveal = (revealMenuBackground, revealMenu) => {
  gsap.from([revealMenuBackground, revealMenu], {
    duration: 0.5,
    height: 0,
    transformOrigin: 'right top',
    skewY: 4,
    ease: 'power3.inOut',
    stagger: 0.1,
  })
}

export { closeMenu, openMenu, staggerReveal }
