import styled from 'styled-components'

export const Application = styled.div`
  background-color: var(--color-white);
  padding: 2rem 2rem;
  border: 0.5rem solid pink;
  border-image: repeating-linear-gradient(45deg, transparent, transparent 5px, var(--color-primary) 6px, var(--color-primary) 15px, transparent 16px, transparent 20px) 20/1rem;

  @media screen and (max-width: 1280px) {
    margin-bottom: 2rem;
  }
`

export const Models = styled.ul`
  padding-left: 1.25rem;

  li::before {
    content: "•";
    display: inline-block;
    font-size: 1.5rem;
    width: 1.22rem;
    margin-left: -1.25rem;
  }
`