import styled from 'styled-components'

export const Column = styled.div`
  width: 100%;

  h2 {
    display: inline-block;
    margin-bottom: 7rem;
    padding: 0rem 1.375rem;
    color: var(--color-white);
    transition: all 0.25s ease;

    &:hover {
      transform-origin: center;
      transform: rotate(-2deg);
      z-index: 10;

      @media screen and (max-width: 768px) {
        transform-origin: unset;
        transform: none;
      }
    }

    span {
        line-height: 1.4;
        background-color: var(--color-primary);
        box-shadow: 20px 0 0px 0px var(--color-primary),
          -20px 0 0px 0px var(--color-primary);
    }
  }

  h4 {
    margin-bottom: 1.125rem;
    text-decoration: underline;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 6rem;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
`