import styled from 'styled-components'

export const StyledHero = styled.div`
  @media screen and (max-width: 1280px) {
    padding-top: 2rem;
  }

  @media screen and (max-width: 560px) {
    padding-top: 0rem;
  }
`

export const Wrapper = styled.div`
  padding: 6rem 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    flex-direction: column-reverse;
    gap: 2.5rem;
    padding: 0rem 0rem 4rem;
  }
`

export const LeftColumn = styled.div`
  width: 42%;
  padding: 1rem 0rem;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
    
  h1 {
    display: inline-block;
    margin-bottom: 2rem;
    padding: 0rem 1.375rem;
    color: var(--color-white);
    transition: all 0.25s ease;

    &:hover {
      transform-origin: center;
      transform: rotate(-2deg);
      z-index: 10;

      @media screen and (max-width: 768px) {
        transform-origin: unset;
        transform: none;
      }
    }

    @media screen and (max-width: 1280px) {
      margin-bottom: 1.5rem;
    }

    span {
      line-height: 1.4;
      background-color: var(--color-primary);
      box-shadow: 20px 0 0px 0px var(--color-primary),
        -20px 0 0px 0px var(--color-primary);
    }
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  h4 {
    font-weight: 400;
  }
`

export const RightColumn = styled.div`
  width: 50%;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  @media screen and (max-width: 560px) {
    height: calc(100vh - 6.5rem);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;

    @media screen and (max-width: 1280px) {
      vertical-align: middle;
    }
  }

  figcaption {
    margin-top: 0.25rem;
    font-size: 1rem;
    font-style: italic;
    text-align: right;

    @media screen and (max-width: 640px) {
      font-size: 0.875rem;
    }
  }
`

export const Arrow = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1280px) {
    display: none;
  }
`

export const ArrowImage = styled.img`
  text-align: center;
  width: 50px;
  animation: scroll 2s infinite;

  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
`
