import styled from 'styled-components'

export const StyledCategories = styled.div`
  padding: 6rem 0rem 7rem;

  @media screen and (max-width: 1280px) {
    padding: 0rem 0rem 4rem;
  }
`

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease;

  &:hover {
    transform-origin: center;
    transform: rotate(-2deg);
    z-index: 10;

    @media screen and (max-width: 768px) {
      transform-origin: unset;
      transform: none;
    }
  }

  h4 {
    font-size: 1.35rem;
    padding: 1rem;
    background: var(--color-primary);
    color: var(--color-white);

    @media screen and (min-width: 1356px) {
      font-size: 1.5rem;
    }
    
    @media screen and (min-width: 1318px) {
      font-size: 1.45rem;
    }
    
    @media screen and (min-width: 1280px) {
      font-size: 1.4rem;
    }
  }
`

export const Image = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
