import { useState, useEffect } from 'react'
import { StyledHeader, Nav, Logo } from './Header.styled'
import { NavLink } from 'react-router-dom'
import palpVillageLogo from '../../assets/images/palp-village.svg'
import Hamburger from 'hamburger-react'
import Menu from '../Menu/Menu'

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  // Prevent scroll in menu
  useEffect(() => {
    if (navbarOpen === true) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [navbarOpen])

  const handleToggle = () => setNavbarOpen((prev) => !prev)

  const closeMenuWithLogo = () => {
    if (navbarOpen) {
      handleToggle()
    }
  }

  return (
    <>
      <StyledHeader>
        <Nav>
          <NavLink to='/' onClick={closeMenuWithLogo}>
            <Logo src={palpVillageLogo} alt='Logo palp village' />
          </NavLink>
          <Hamburger toggled={navbarOpen} toggle={handleToggle} color='#202020' distance='sm' />
        </Nav>
      </StyledHeader>
      <Menu state={navbarOpen} toggle={handleToggle} />
    </>
  )
}

export default Header
