import { Main, Container, Wrapper, LeftColumn, Paragraph, RightColumn, Image, List, Link } from '../../globalStyles'
import flyer from '../../assets/images/market/flyer.jpg'

const Market = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Grand Marché</span>
              <br/>
              <span>des Terroirs Alpins</span>
            </h2>
            <Paragraph>
            <h4>Idée générale</h4>
              <p>Le patrimoine alimentaire est un élément fondamental de l’Arc alpin, de la Slovénie à la France, en passant par l’Italie, l’Autriche, l’Allemagne, le Liechtenstein et la Suisse. Il constitue une des bases de l’identité culturelle de ces régions, contribue à la durabilité de nos Alpes, participe à préserver des paysages productifs et des terroirs vivants.</p>
              <p>En créant un marché dédié à ses produits, nous voulons valoriser les savoir-faire des producteurs et des artisans, mettre en lumière les pratiques et gestes ancestraux qui sont au coeur de nos montagnes, placer au coeur de nos assiettes les pratiques culinaires liés à ces spécialités.</p>
              <p>Le Grand Marché des Terroirs Alpins, ce sera deux jours de dégustations, de découvertes, de ventes directes, de rencontre, d’ateliers, de concerts et d’expériences culinaires.</p>
              <p>Un rendez-vous alpin incontournable à la fois pour les producteurs et les consommateurs!</p>
            </Paragraph>
            <Paragraph>
              <h4>Objectifs</h4>
              <List>
                <li>Offrir une vitrine nationale et internationale au terroir alpin, aux producteurs et aux traditions alpines</li>
                <li>Mettre en avant la grande richesse, la haute qualité et la diversification des produits de ces régions</li>
                <li>Faire découvrir les techniques de production et les filières agricoles de nos montagnes</li>
                <li>Placer les producteurs au coeur du projet en proposant une place centrale dans l’événement</li>
                <li>Favoriser la (re)découverte et la promotion d’un patrimoine naturel, immatériel et environnemental exceptionnel partagé par plusieurs régions des Alpes</li>
                <li>Renforcer les occasions d’échanges interculturels et offrir un lieu propice aux rencontres</li>
                <li>Créer des échanges et de la proximité entre producteurs et consommateurs</li>
                <li>Remettre au goût du jour des traditions et recettes oubliées</li>
                <li>Favoriser une prise de conscience environnementale et durable</li>
                <li>Offrir un cadre et une organisation propice aux échanges</li>
                <li>Proposer un panel d’activités et d’ateliers autour de la thématique</li>
              </List>
            </Paragraph>
            <Paragraph>
              <h4>Guide pratique</h4>
              <Link href='https://palpfestival.ch/evenements/grand-marche-terroirs-alpins/' target='_blank' rel='noreferrer'>Programme</Link>
            </Paragraph>
            <Paragraph>
                <h4>Où</h4>
                <p>Au coeur de l’arc alpin, dans le village de Bruson dans le val de Bagnes en Valais.</p>
            </Paragraph>
            <Paragraph>
                <h4>Quand</h4>
                <p>Les 15 et 16 juin 2024.</p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={flyer} alt="Flyer" />
              <figcaption>© PALP Village</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  )
}

export default Market
